// utils/currencyService.js
import axios from 'axios';

const BASE_CURRENCY = 'VND'; // Your base currency

// Replace 'YOUR_API_KEY' with your actual API key
const EXCHANGE_RATE_API = `https://v6.exchangerate-api.com/v6/0e8ae13497737af35bec76ed/latest/${BASE_CURRENCY}`;

let exchangeRates = null; // Store exchange rates to avoid multiple API calls

// Fetch exchange rates from the API
export const fetchExchangeRates = async () => {
    try {
        if (!exchangeRates) {
            const response = await axios.get(EXCHANGE_RATE_API);
            exchangeRates = response.data.conversion_rates;
        }
        return exchangeRates;
    } catch (error) {
        console.error("Error fetching exchange rates:", error);
        return null;
    }
};

// Convert from VND to the target currency
export const convertCurrency = (amount, targetCurrency) => {
    if (!exchangeRates) return amount;
    const rate = exchangeRates[targetCurrency] || 1;
    return amount * rate;
};

//back to this
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

function DecryptCardDetailsScreen() {
    const { bookingId } = useParams(); // Retrieve the bookingId from the URL
    const location = useLocation(); // Retrieve the state passed from the previous screen
    const password = location.state?.password || ''; // Password passed from the previous screen
    const [cardDetails, setCardDetails] = useState(null); // State to hold the decrypted card details
    const [bookingInfo, setBookingInfo] = useState({}); // State to hold booking info

    useEffect(() => {
        const handleDecrypt = async () => {
            try {
                const response = await axios.get(`http://localhost:5001/api/bookings/decryptcard`, {
                    params: {
                        bookingId,
                        password, // Pass the password with the request
                    },
                });
                setCardDetails(response.data.cardDetails); // Store the decrypted card details
                setBookingInfo({
                    bookingId: response.data.bookingId, // Store bookingId
                    totalamount: response.data.totalamount, // Store totalamount
                });
            } catch (error) {
                Swal.fire('Error', 'Failed to retrieve card details. Please check the booking ID or password.', 'error');
            }
        };

        if (password) {
            handleDecrypt();
        }
    }, [bookingId, password]); // Include bookingId and password as dependencies

    return (
        <div className="container">
            <h2>Card Details</h2>

            {/* Show Booking ID and Total Amount */}
            <div className="card mt-4 mb-5">
                <div className="card-body">
                    <h5 className="card-title">Booking Information</h5>
                    <p><strong>Booking ID:</strong> {bookingInfo.bookingId}</p>
                    <p><strong>Total Amount:</strong> {bookingInfo.totalamount ? `${bookingInfo.totalamount} VND` : 'N/A'}</p>
                </div>
            </div>

            {/* Show Decrypted Card Information */}
            {cardDetails ? (
                <div className="card mt-4 mb-5">
                    <div className="card-body">
                        <h5 className="card-title">Card Information</h5>
                        <p><strong>Card Number:</strong> {cardDetails.cardNumber}</p>
                        <p><strong>Expiry Date:</strong> {cardDetails.cardExpiry}</p>
                        <p><strong>CVV:</strong> {cardDetails.cardCVC}</p>
                    </div>
                </div>
            ) : (
                <p>Loading decrypted card details...</p>
            )}
        </div>
    );
}

export default DecryptCardDetailsScreen;

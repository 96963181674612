// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation resources
const resources = {
    en: {
        translation: {
            navbar: {
                homepage: "Homepage",
                direction: "Direction",
                register: "Register",
                login: "Login",
                profile: "Profile",
                admin_panel: "Admin Panel",
                logout: "Logout"
            },
            room: {
                max_adults: "Max Adults",
                max_children: "Max Children",
                extra_bed: "Extra Bed",
                facilities: "Facilities",
                smoking_policy: "Smoking Allowed",
                no_smoking: "No Smoking",
                view: "View",
                bedding: "Bedding",
                room_size: "Room Size",
                room_type: "Room Type",
                average_rating: 'Average Rating',
                rate_this_room: "Rate this Room",
                submit_rating: "Submit Rating",
                description: "Description",
                details: "View Details",
                rate_plan: "Rate Plan",
                meal_plan: "Meal Plan",
                cancellation_policy: "Cancellation Policy",
                pick: "Pick",
                add_on_services: "Add-on Services",
                more_info: "More Info",
                service_name: "Service Name",
                service_type: "Service Type",
                charge_type: "Charge Type",
                price: "Price",
                date_applied: "Date Applied",
                close: "Close",
                validate: "Validate",
                booking_id: "Booking ID",
                guest_name: "Guest Name",
                rate_the_room: "Rate the Room",
                your_review: "Your Review",
                pick_addon_service: "Pick Add-on Service"
            }
        }
    },
    vi: {
        translation: {
            navbar: {
                homepage: "Trang chủ",
                direction: "Chỉ đường",
                register: "Đăng ký",
                login: "Đăng nhập",
                profile: "Hồ sơ",
                admin_panel: "Quản Lý",
                logout: "Đăng xuất"
            },
            room: {
                max_adults: "Số người lớn tối đa",
                max_children: "Số trẻ em tối đa",
                extra_bed: "Giường phụ",
                facilities: "Tiện nghi",
                smoking_policy: "Cho phép hút thuốc",
                no_smoking: "Không hút thuốc",
                view: "Hướng nhìn",
                bedding: "Loại Giường",
                room_size: "Diện tích phòng",
                room_type: "Loại phòng",
                rate_this_room: "Đánh giá phòng",
                submit_rating: "Gửi đánh giá",
                average_rating: "Điểm đánh giá",
                description: "Mô tả phòng",
                details: "Xem Chi Tiết",
                rate_plan: "Gói giá",
                meal_plan: "Kế hoạch bữa ăn",
                cancellation_policy: "Chính sách hủy",
                pick: "Chọn",
                add_on_services: "Dịch vụ bổ sung",
                more_info: "Thêm thông tin",
                service_name: "Tên dịch vụ",
                service_type: "Loại dịch vụ",
                charge_type: "Hình thức tính phí",
                price: "Giá",
                date_applied: "Ngày áp dụng",
                close: "Đóng",
                validate: "Xác thực",
                booking_id: "Mã đặt phòng",
                guest_name: "Tên khách",
                rate_the_room: "Đánh giá phòng",
                your_review: "Đánh giá của bạn",
                pick_addon_service: "Chọn dịch vụ bổ sung"
            }
        }
    },
    ko: {
        translation: {
            navbar: {
                homepage: "홈페이지",
                direction: "길 찾기",
                register: "회원가입",
                login: "로그인",
                profile: "프로필",
                admin_panel: "관리자 패널",
                logout: "로그아웃"
            },
            room: {
                max_adults: "최대 성인 수",
                max_children: "최대 어린이 수",
                extra_bed: "추가 침대",
                facilities: "시설",
                smoking_policy: "흡연 가능",
                no_smoking: "금연",
                view: "전망",
                bedding: "침구",
                room_size: "방 크기",
                room_type: "방 유형",
                rate_this_room: "이 방 평가하기",
                submit_rating: "평가 제출",
                description: "설명",
                average_rating: "평균 평점",
                details: "자세히 보기",
                rate_plan: "요금제",
                meal_plan: "식사 계획",
                cancellation_policy: "취소 정책",
                pick: "선택",
                add_on_services: "추가 서비스",
                more_info: "추가 정보",
                service_name: "서비스 이름",
                service_type: "서비스 유형",
                charge_type: "요금 유형",
                price: "가격",
                date_applied: "적용 날짜",
                close: "닫기",
                validate: "확인",
                booking_id: "예약 ID",
                guest_name: "고객 이름",
                rate_the_room: "방 평가",
                your_review: "귀하의 리뷰",
                pick_addon_service: "추가 서비스 선택"
            },
            homescreen: {
                check_in: "Check-in",
                check_out: "Check-out",
                adults: "Adults",
                children: "Children",
                promo_code: "Promo Code",
                apply: "Apply",
                loading: "Loading...",
                your_bookings: "YOUR BOOKINGS",
                grand_total: "Grand Total",
                continue_checkout: "Continue to Checkout",
                open_sidebar: "Open Sidebar",
                add_on_service: "Add-On Service",
                quantity: "Quantity",
                room_rate: "Room Rate",
                rate_plan: "Rate Plan",
                room: "Room",
                extra_bed: "Extra Bed",
                yes: "Yes",
                no: "No",
            },
        }
    },
    zh: {
        translation: {
            navbar: {
                homepage: "主页",
                direction: "路线",
                register: "注册",
                login: "登录",
                profile: "个人资料",
                admin_panel: "管理员面板",
                logout: "退出"
            },
            room: {
                max_adults: "最多成年人",
                max_children: "最多儿童",
                extra_bed: "加床",
                facilities: "设施",
                smoking_policy: "允许吸烟",
                no_smoking: "不允许吸烟",
                view: "视野",
                bedding: "床铺",
                room_size: "房间大小",
                room_type: "房间类型",
                rate_this_room: "评价此房间",
                submit_rating: "提交评价",
                description: "描述",
                average_rating: "平均评分",
                details: "查看详情",
                rate_plan: "价格计划",
                meal_plan: "餐饮计划",
                cancellation_policy: "取消政策",
                pick: "选择",
                add_on_services: "附加服务",
                more_info: "更多信息",
                service_name: "服务名称",
                service_type: "服务类型",
                charge_type: "收费类型",
                price: "价格",
                date_applied: "应用日期",
                close: "关闭",
                validate: "验证",
                booking_id: "预订 ID",
                guest_name: "客人姓名",
                rate_the_room: "评价房间",
                your_review: "您的评论",
                pick_addon_service: "选择附加服务"

            }
        }
    },
    ja: {
        translation: {
            navbar: {
                homepage: "ホームページ",
                direction: "道順",
                register: "登録",
                login: "ログイン",
                profile: "プロフィール",
                admin_panel: "管理パネル",
                logout: "ログアウト"
            },
            room: {
                max_adults: "大人の最大人数",
                max_children: "子供の最大人数",
                extra_bed: "追加ベッド",
                facilities: "施設",
                smoking_policy: "喫煙可",
                no_smoking: "禁煙",
                view: "景色",
                bedding: "寝具",
                room_size: "部屋の広さ",
                room_type: "部屋の種類",
                rate_this_room: "この部屋を評価する",
                submit_rating: "評価を提出する",
                description: "説明",
                average_rating: "平均評価",
                details: "詳細を見る",
                rate_plan: "料金プラン",
                meal_plan: "食事プラン",
                cancellation_policy: "キャンセルポリシー",
                pick: "選ぶ",
                add_on_services: "追加サービス",
                more_info: "詳細情報",
                service_name: "サービス名",
                service_type: "サービスの種類",
                charge_type: "料金の種類",
                price: "価格",
                date_applied: "適用日",
                close: "閉じる",
                validate: "確認",
                booking_id: "予約 ID",
                guest_name: "ゲスト名",
                rate_the_room: "部屋を評価する",
                your_review: "あなたのレビュー",
                pick_addon_service: "追加サービスを選択"
            }
        }
    }
};

// Initialize i18n
i18n
    .use(initReactI18next) // Pass i18n instance to react-i18next
    .init({
        resources,
        lng: 'en', // Set default language
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
    });

export default i18n;

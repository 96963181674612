//back to this
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import dropin from 'braintree-web-drop-in';  // Import Braintree Drop-in
import './Room2.css'
import { CurrencyContext } from '../components/CurrencyContext';

function Bookingscreen() {
    const location = useLocation();
    const { bookingSidebar } = location.state || {};
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const [guestName, setGuestName] = useState('');
    const [guestEmail, setGuestEmail] = useState('');
    const [guestPhoneNumber, setGuestPhoneNumber] = useState('');
    const [guestNationality, setGuestNationality] = useState('');
    const [specialRequest, setSpecialRequest] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('paypal');
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVC, setCardCVC] = useState('');
    const [extraBedPrice, setExtraBedPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [braintreeInstance, setBraintreeInstance] = useState(null);
    const [branding, setBranding] = useState('');
    const { currency, convertAmount } = useContext(CurrencyContext);

    const navigate = useNavigate();

    const braintreeRef = useRef(null); // Ref for the Braintree Drop-in UI container

    // Refs for guest information
    const guestNameRef = useRef('');
    const guestEmailRef = useRef('');
    const guestPhoneNumberRef = useRef('');
    const guestNationalityRef = useRef('');
    const specialRequestRef = useRef('');

    const formatCurrency = (amount) => {
        const locales = {
            USD: 'en-US',
            VND: 'vi-VN',
            EUR: 'de-DE',
            JPY: 'ja-JP',
            CNY: 'zh-CN',
            KRW: 'ko-KR',
            THB: 'th-TH',
        };

        const currencySymbols = {
            USD: 'USD',
            VND: 'VND',
            EUR: 'EUR',
            JPY: 'JPY',
            CNY: 'CNY',
            KRW: 'KRW',
            THB: 'THB',
        };

        const convertedAmount = convertAmount(amount, currency);

        return new Intl.NumberFormat(locales[currency] || 'vi-VN', {
            style: 'currency',
            currency: currencySymbols[currency] || 'VND',
            maximumFractionDigits: 0,
        }).format(convertedAmount);
    };

    useEffect(() => {
        // Fetch extra bed price and selected payment method from the backend
        const fetchData = async () => {
            try {
                const extraBedPriceResponse = await axios.get('/api/extrabedprice/get');
                setExtraBedPrice(extraBedPriceResponse.data.price);

                const paymentMethodResponse = await axios.get('/api/paymentmethod/getPaymentMethod');
                setPaymentMethod(paymentMethodResponse.data.method);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

        // Initialize Braintree Drop-in UI
        const initializeBraintree = async () => {
            try {
                const clientTokenResponse = await axios.get('/api/paymentmethod/getBraintreeToken');
                const clientToken = clientTokenResponse.data.clientToken;

                dropin.create({
                    authorization: clientToken,
                    container: braintreeRef.current
                }, (error, instance) => {
                    if (error) {
                        console.error('Error creating Braintree drop-in:', error);
                    } else {
                        setBraintreeInstance(instance);
                    }
                });
            } catch (error) {
                console.error('Error initializing Braintree:', error);
            }
        };

        initializeBraintree();
    }, []);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await axios.get('/api/branding/getallbranding');
                setBranding(response.data || { roomNameColor: '#443d80' });
            } catch (error) {
                console.error("Error fetching branding settings:", error);
            }
        };

        fetchBranding();
    }, []);

    const calculateTotalAmount = () => {
        return bookingSidebar?.reduce((acc, plan) => {
            const roomPrice = plan.type !== 'addonservice' ? plan.totalRate * plan.selectedRooms : 0;
            const extraBedCharge = plan.extraBed
                ? Object.values(plan.extraBed || {}).reduce((sum, isExtraBed) => sum + (isExtraBed ? extraBedPrice : 0), 0)
                : 0;
            const addOnServicePrice = plan.type === 'addonservice' ? plan.servicePrice : 0;

            return acc + roomPrice + extraBedCharge + addOnServicePrice;
        }, 0);
    };

    const calculateTotalDays = () => {
        if (bookingSidebar?.length > 0) {
            const fromdate = moment(bookingSidebar[0].fromdate, 'DD-MM-YYYY');
            const todate = moment(bookingSidebar[0].todate, 'DD-MM-YYYY');
            return todate.diff(fromdate, 'days');
        }
        return 0;
    };

    useEffect(() => {
        // Load PayPal SDK
        const script = document.createElement("script");
        script.src = "https://www.paypal.com/sdk/js?client-id=AfYguK6owoLNLgN2lBJN3m8AST8zeX2swwYqcLHmV9WSy9z-7b7ibTDyVaRBICEa0bcXRtE66i_wvF1N";
        script.setAttribute("data-sdk-integration-source", "developer-studio");

        script.addEventListener("load", () => {
            console.log("PayPal SDK loaded successfully.");
            if (window.paypal) {
                setUpPayPalButton();
            } else {
                console.error("PayPal SDK did not initialize correctly.");
                Swal.fire('Error', 'PayPal SDK could not be loaded. Please try again later.', 'error');
            }
        });

        script.addEventListener("error", (err) => {
            console.error("Failed to load PayPal SDK:", err);
            Swal.fire('Error', 'PayPal SDK could not be loaded. Please try again later.', 'error');
        });
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePayPalOrder = async (orderId) => {
        // Use refs to get guest information
        const name = guestNameRef.current.trim();
        const email = guestEmailRef.current.trim();
        const phone = guestPhoneNumberRef.current.trim();
        const nationality = guestNationalityRef.current.trim();
        const request = specialRequestRef.current.trim();

        if (!name || !email || !phone || !nationality || !request) {
            Swal.fire('Error', 'All guest information fields are required.', 'error');
            return;
        }
        setLoading(true);
        try {
            const totalamount = calculateTotalAmount();
            const totaldays = calculateTotalDays();

            // Construct booking details for PayPal
            const bookingDetails = {
                bookingSidebar,
                userid: currentUser ? currentUser._id : 'guest',
                totalamount,
                totaldays,
                guestName: name,
                guestEmail: email,
                guestPhoneNumber: phone,
                guestNationality: nationality,
                specialRequest: request,
                paymentMethod: 'paypal',
                orderId, // Include the PayPal order ID
            };

            console.log("PayPal Booking Details sent to backend:", bookingDetails);

            // Send the booking details to the backend
            const response = await axios.post('/api/bookings/capturepaypalorder', bookingDetails);
            Swal.fire({
                title: 'Success',
                text: 'Your reservation was booked successfully',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(async () => {
                const { data: hotelInfo } = await axios.get('/api/hotelInfo/getallinfo'); // Fetch hotel info
                navigate('/confirmation', { state: { booking: response.data.booking, hotelInfo } });
            });
        } catch (error) {
            console.error('Error capturing PayPal order:', error);
            Swal.fire('Payment failed', 'Unable to complete the booking.', 'error');
        } finally {
            setLoading(false);
        }
    };

    const setUpPayPalButton = () => {
        if (window.paypal) {
            console.log("Initializing PayPal Buttons...");
            window.paypal.Buttons({
                createOrder: async () => {
                    const name = guestNameRef.current.trim();
                    const email = guestEmailRef.current.trim();
                    const phone = guestPhoneNumberRef.current.trim();
                    const nationality = guestNationalityRef.current.trim();
                    const request = specialRequestRef.current.trim();

                    if (!name || !email || !phone || !nationality || !request) {
                        Swal.fire('Error', 'All guest information fields are required.', 'error');
                        throw new Error('Missing guest information.');
                    }
                    console.log("Creating PayPal order...");

                    const roomPrice = bookingSidebar.reduce((acc, item) => acc + (item.type !== 'addonservice' ? item.totalRate * item.selectedRooms : 0), 0);
                    const addOnServicePrice = bookingSidebar.reduce((acc, item) => acc + (item.type === 'addonservice' ? item.servicePrice : 0), 0);

                    try {
                        const response = await axios.post('/api/bookings/createPayPalOrder', {
                            totalAmount: calculateTotalAmount(),
                            currency,
                            bookingSidebar,
                            breakdownDetails: { roomPrice, addOnServicePrice },
                        });
                        console.log("Order ID:", response.data.orderId);
                        return response.data.orderId;
                    } catch (error) {
                        console.error("Error creating PayPal order:", error);
                        throw error;
                    }
                },
                onApprove: async (data) => {
                    console.log("PayPal approved:", data);
                    try {
                        await handlePayPalOrder(data.orderID);
                    } catch (error) {
                        console.error("Error completing PayPal booking:", error);
                    }
                },
                onError: (err) => {
                    console.error("PayPal button error:", err);
                },
            }).render("#paypal-button-container").catch(err => {
                console.error("Error rendering PayPal button:", err);
            });
        } else {
            console.error("PayPal SDK not ready.");
        }
    };

    const handleBraintreePayment = async () => {
        try {
            if (!braintreeInstance) throw new Error("Braintree instance not initialized");

            // Request payment method from Braintree Drop-in
            const { nonce } = await braintreeInstance.requestPaymentMethod();
            return nonce; // Return the nonce to use as transaction ID
        } catch (error) {
            console.error('Error processing Braintree payment:', error);
            Swal.fire('Payment failed', 'Braintree payment could not be completed.', 'error');
            throw error;
        }
    };

    const handleBooking = async () => {
        console.log('Guest Name:', guestName);
        console.log('Guest Email:', guestEmail);
        console.log('Guest Phone Number:', guestPhoneNumber);
        console.log('Guest Nationality:', guestNationality);

        if (!guestName || !guestEmail || !guestPhoneNumber || !guestNationality) {
            Swal.fire('Error', 'All guest information fields are required.', 'error');
            return;
        }
        setLoading(true);
        try {
            let braintreeNonce = null;

            if (paymentMethod === 'braintree') {
                braintreeNonce = await handleBraintreePayment(); // Braintree nonce
            }

            const totalamount = calculateTotalAmount();
            const totaldays = calculateTotalDays();

            const bookingDetails = {
                bookingSidebar,
                userid: currentUser ? currentUser._id : 'guest',
                totalamount,
                totaldays,
                guestName,
                guestEmail,
                guestPhoneNumber,
                guestNationality,
                specialRequest,
                paymentMethod,
                ...(paymentMethod === 'creditCard' && { cardNumber, cardExpiry, cardCVC }),
                ...(paymentMethod === 'braintree' && { braintreeNonce }),
            };

            console.log("Booking Details sent to backend:", bookingDetails); // Log booking details
            const response = await axios.post('/api/bookings/bookroom', bookingDetails);
            Swal.fire({
                title: 'Success',
                text: 'Your reservation was booked successfully',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(async () => {
                const { data: hotelInfo } = await axios.get('/api/hotelInfo/getallinfo'); // Fetch hotel info
                navigate('/confirmation', { state: { booking: response.data.booking, hotelInfo } });

            });
        } catch (error) {
            console.error('Booking error:', error);
            Swal.fire('Oops, Something went wrong', 'error');
        } finally {
            setLoading(false);
        }
    };

    if (!bookingSidebar || bookingSidebar.length === 0) {
        return <div>No booking details available. Please select a room and rate plan.</div>;
    }

    return (
        <div
            className='background'
            style={{
                backgroundImage: `url(${branding.pageBackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <div className="container">
                <div className="row mt-5 gap-3">
                    <div className="glass-effect2 col-md-6"
                        style={{
                            background: branding.searchBarGradient,
                        }}
                    >
                        <h1 style={{ color: '#FAF3FF' }}>Your Booking Details</h1>
                        {bookingSidebar.length > 0 && (
                            <div className='text-white'>
                                <p><strong>Check In:</strong> {bookingSidebar[0].fromdate}</p>
                                <p><strong>Check Out:</strong> {bookingSidebar[0].todate}</p>
                                <p><strong>Total Days:</strong> {calculateTotalDays()}</p>
                            </div>
                        )}
                        <ul className="list-group mb-4">
                            {/* Display Rooms */}
                            {bookingSidebar
                                .filter(item => item.type !== 'addonservice')  // Filter out addon services for room rendering
                                .map((item, index) => (
                                    <li className="bs list-group-item d-flex align-items-start" key={index}>

                                        <div>
                                            {item.roomName && item.ratePlanName && (
                                                <>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <h1 style={{ fontSize: '2.5rem', color: '#433d80' }}>
                                                            <strong>{item.roomName}</strong>
                                                        </h1>
                                                        {item.imageurls && item.imageurls[0] && (
                                                            <img
                                                                src={item.imageurls[0]}
                                                                alt={item.roomName}
                                                                style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '5px'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <p>
                                                        <strong>Rate Plan: </strong>
                                                        <span style={{ color: branding.paragraphColor }}> {item.ratePlanName}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Meal Plan: </strong>
                                                        <span style={{ color: branding.paragraphColor }}> {item.mealPlan || 'None'}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Number of Rooms Booked: </strong>
                                                        <span style={{ color: branding.paragraphColor }}> {item.selectedRooms}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Adults: </strong>
                                                        <span style={{ color: branding.paragraphColor }}>
                                                            {item.selectedAdults ? Object.values(item.selectedAdults).reduce((a, b) => a + b, 0) : 0}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <strong>Children: </strong>
                                                        <span style={{ color: branding.paragraphColor }}>
                                                            {item.selectedChildren ? Object.values(item.selectedChildren).reduce((a, b) => a + b, 0) : 0}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <strong>Total Rate: </strong>
                                                        <span style={{ color: branding.paragraphColor }}>
                                                            {formatCurrency(item.totalRate * item.selectedRooms)} {currency}
                                                        </span>
                                                    </p>
                                                    {item.extraBed && Object.values(item.extraBed).some(isExtraBed => isExtraBed) && (
                                                        <p>
                                                            <strong>Extra Bed Price: </strong>
                                                            <span style={{ color: branding.paragraphColor }}>{formatCurrency(extraBedPrice)} {currency}</span>
                                                        </p>
                                                    )}
                                                    {item.cancellationPolicy && (
                                                        <p>
                                                            <strong>Cancellation Policy: </strong>
                                                            <span style={{ color: branding.paragraphColor }}> {item.cancellationPolicy}</span>
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </li>
                                ))}

                            {/* Display Add-On Services */}
                            <h5 className='mt-3 text-white'>Add-On Services</h5>
                            {bookingSidebar
                                .filter(item => item.type === 'addonservice')  // Filter only addon services
                                .map((item, index) => (
                                    <li className="bs list-group-item" key={index}>
                                        <p>
                                            <strong>Service Name:</strong>
                                            <span style={{ color: branding.paragraphColor }}> {item.serviceName}</span>
                                        </p>
                                        <p>
                                            <strong>Description:</strong>
                                            <span style={{ color: branding.paragraphColor }}> {item.serviceDescription}</span>
                                        </p>
                                        <p>
                                            <strong>Service Price:</strong>
                                            <span style={{ color: branding.paragraphColor }}> {formatCurrency(item.servicePrice)} {currency}</span>
                                        </p>
                                    </li>
                                ))}
                        </ul>
                        <h3 className="bs">
                            <strong>Grand Total: <span style={{ color: branding.paragraphColor }}>{formatCurrency(calculateTotalAmount())}</span> {currency} </strong>
                        </h3>

                    </div>

                    <div className="glass-effect2 col-md-5 text-white"
                        style={{
                            background: branding.searchBarGradient,
                        }}
                    >
                        <h1>Guest Information</h1>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (paymentMethod === 'paypal') {
                                    handlePayPalOrder(); // Trigger PayPal order handling
                                } else {
                                    handleBooking(); // Trigger booking for other payment methods
                                }
                            }}
                        >
                            <div className="mb-3">
                                <label htmlFor="guestName" className="form-label">
                                    <strong>Guest Name</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="guestName"
                                    placeholder="Enter your name"
                                    value={guestName}
                                    onChange={(e) => {
                                        setGuestName(e.target.value);
                                        guestNameRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="guestEmail" className="form-label">
                                    <strong>Email address</strong>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="guestEmail"
                                    placeholder="Enter your email"
                                    value={guestEmail}
                                    onChange={(e) => {
                                        setGuestEmail(e.target.value);
                                        guestEmailRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="guestPhoneNumber" className="form-label">
                                    <strong>Phone Number</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="guestPhoneNumber"
                                    placeholder="Enter your phone number"
                                    value={guestPhoneNumber}
                                    onChange={(e) => {
                                        setGuestPhoneNumber(e.target.value);
                                        guestPhoneNumberRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="guestNationality" className="form-label">
                                    <strong>Nationality</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="guestNationality"
                                    placeholder="Enter your nationality"
                                    value={guestNationality}
                                    onChange={(e) => {
                                        setGuestNationality(e.target.value);
                                        guestNationalityRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="specialRequest" className="form-label">
                                    <strong>Special Requests</strong>
                                </label>
                                <textarea
                                    className="form-control"
                                    id="specialRequest"
                                    rows="3"
                                    placeholder="Any special requests?"
                                    value={specialRequest}
                                    onChange={(e) => {
                                        setSpecialRequest(e.target.value);
                                        specialRequestRef.current = e.target.value;
                                    }}
                                />
                            </div>

                            {/* Payment Method Section */}
                            <h1 className="mt-3">Check-out</h1>
                            {paymentMethod === 'creditCard' && (
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="cardNumber" className="form-label">
                                            <strong>Credit Card Number</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cardNumber"
                                            placeholder="Enter your credit card number"
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="cardExpiry" className="form-label">
                                            <strong>Expiry Date (MM/YY)</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cardExpiry"
                                            placeholder="MM/YY"
                                            value={cardExpiry}
                                            onChange={(e) => setCardExpiry(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="cardCVC" className="form-label">
                                            <strong>CVV</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cardCVC"
                                            placeholder="Enter your CVV"
                                            value={cardCVC}
                                            onChange={(e) => setCardCVC(e.target.value)}
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            {paymentMethod === 'payAtHotel' && <p>You will pay upon arrival at the hotel.</p>}
                            {paymentMethod === 'braintree' && (
                                <div className="mb-3">
                                    <div ref={braintreeRef} />
                                </div>
                            )}

                            {/* Show PayPal button for PayPal payment */}
                            {paymentMethod === 'paypal' ? (
                                <div id="paypal-button-container" style={{ marginBottom: '20px' }}></div>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100 mb-5 bs"
                                    disabled={loading}
                                    style={{ marginBottom: '15px', marginTop: '1rem', color: '#4d4692', fontWeight: '800' }}
                                >
                                    {loading ? 'Processing...' : 'BOOK NOW'}
                                </button>
                            )}
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bookingscreen;


import React from 'react';
import { useLocation } from 'react-router-dom';

const ConfirmationScreen = () => {
    const location = useLocation();
    const { booking, hotelInfo } = location.state || {}; // Get booking and hotelInfo from state

    if (!booking || !hotelInfo) {
        return <p>Error: Booking or Hotel Information is missing!</p>;
    }

    const roomDetailsRows = booking.rooms.map((room, index) => (
        <tr key={index}>
            <td>{room.room}</td>
            <td>{room.numberOfRooms}</td>
            <td>{room.ratePlanName}</td>
            <td>{room.ratePlanRate}</td>
            <td>{room.adults}</td>
            <td>{room.children}</td>
            <td>{room.mealPlan || 'None'}</td>
            <td>{room.cancellationPolicy || 'Flexible'}</td>
        </tr>
    ));

    const addonServiceDetails = booking.addonServices.length > 0
        ? booking.addonServices.map((service, index) => (
            <p key={index}>
                {service.serviceName}: {service.servicePrice} VND
            </p>
        ))
        : <p>No add-on services selected.</p>;

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', padding: '36px', backgroundColor: '#f4f4f4' }}>
            <div style={{
                maxWidth: '100%',
                margin: 'auto',
                backgroundColor: '#fff',
                padding: '30px',
                borderRadius: '10px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <div style={{
                    backgroundColor: '#4A148C',
                    color: 'white',
                    padding: '15px',
                    textAlign: 'center',
                    borderRadius: '10px 10px 0 0'
                }}>
                    <h1>Booking Confirmation</h1>
                </div>
                <div style={{ padding: '20px' }}>
                    <h2>Hotel Information</h2>
                    <img src={hotelInfo.logo} alt="Hotel Logo" style={{ maxWidth: '200px' }} />
                    <p><strong>{hotelInfo.hotelName}</strong></p>
                    <p>{hotelInfo.address}</p>
                    <p>Phone: {hotelInfo.phoneNumber}</p>
                    <p><a href={hotelInfo.mapLocation}>View on Map</a></p>
                    <p><a href={hotelInfo.socialMediaLinks?.officialWebsite}>Visit Website</a></p>

                    <h2>Booking Details</h2>
                    <table style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        marginTop: '20px',
                        border: '1px solid #ddd',
                    }}>
                        <tbody>
                            <tr>
                                <th>Booking ID</th>
                                <td>{booking.bookingId}</td>
                            </tr>
                            <tr>
                                <th>Check-In Date</th>
                                <td>{booking.fromdate}</td>
                            </tr>
                            <tr>
                                <th>Check-Out Date</th>
                                <td>{booking.todate}</td>
                            </tr>
                            <tr>
                                <th>Total Days</th>
                                <td>{booking.totaldays}</td>
                            </tr>
                            <tr>
                                <th>Total Amount</th>
                                <td>{booking.totalamount} VND</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>Room Details</h2>
                    <table style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        marginTop: '20px',
                        border: '1px solid #ddd',
                    }}>
                        <thead>
                            <tr>
                                <th>Room</th>
                                <th>Rooms</th>
                                <th>Rate Plan</th>
                                <th>Rate</th>
                                <th>Adults</th>
                                <th>Children</th>
                                <th>Meal Plan</th>
                                <th>Cancellation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roomDetailsRows}
                        </tbody>
                    </table>

                    <h2>Add-On Services</h2>
                    {addonServiceDetails}

                    <p>Thank you for booking with us!</p>
                    <p>We look forward to welcoming you!</p>
                    <p>Regards,<br />{hotelInfo.hotelName} Team</p>
                </div>
                <div style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    fontSize: '12px',
                    color: '#777'
                }}>
                    <p>Need help? Contact us at {hotelInfo.email}</p>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationScreen;
